<mat-drawer-container autosize="">
	<mat-drawer #drawer opened="false" mode="push" position="end">
			<mat-nav-list>
				<!-- <mat-list-item> -->
					<section class="wrapSlideMenuHeader">
						<p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.JumpToSection" | translate}}</p>
						<a id="dnload-wkst" class="makeCertainSize" mat-raised-button href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.ViewWorksheet" | translate}}</a>
					</section>
					<section class="wrapSlideMenuBody">
						<div class="headerDivInSlideMenuBody">
							<h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionFirst" | translate}}</b></h4>
							<p>{{ getCompletedSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Eleven_Completed" | translate}}</p>
						</div>
	
						<section class="mainNavSectionInSlideMenuBody">
						<!-- First Part -->
							<div class="wrapDotsAndWords">
								<div class="navigationDotsInSlideMenu">
									<div class="navigationDotsInSlideMenu">
										<ng-container *ngFor="let status of sectionStatus; let i = index; let last = last">
											<a class="dots" [ngClass]="{ 'outerBorder': status }">
												<div class="mini-dot" [ngClass]="{ 'activeDot': status }">
													<svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
														<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
														<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
													</svg>  
												</div>
											</a>
											<span *ngIf="!last"></span>
										</ng-container>
									</div>
								</div>
								<div class="textCloseToDotsInSideMenu" *ngIf="marketValue !='US' && marketValue !='CA'">
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="diversity_pre"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate}}</p>
										<span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg>                                      
										</span>
									</span></a>                                
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="gender"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>                                
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="age"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>                                
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="generation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="evs"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>                               
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>                               
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_personas"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>                              
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>                               
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest_representation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionNine" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTen" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEleven" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
								</div>
	
								<!-- US market -->
								<div class="textCloseToDotsInSideMenu" *ngIf="marketValue =='US'">                                    
										<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="Inclusive_Culture"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate}}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
													<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
													<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
													</g>
												</svg>                                      
											</span>
										</span></a>
										<span></span>                                    
										<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="ev"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
										<span></span>                                  
										<a (click)="closeSideMenu()" ><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
										<span></span>                                 
		
										<a (click)="closeSideMenu()" ><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="ford_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
										<span></span>                                    
										<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
										<span></span>                                   
										<a (click)="closeSideMenu()" ><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest-representation"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
										<span></span>
									   
										<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
										<span></span>   
										
										<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate}}</p><span class="arrowheadInSideMenu">
											<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
												<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
												<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
												<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
												</g>
											</svg> </span></span></a>
								</div>

								<!-- CA market -->
								<div class="textCloseToDotsInSideMenu" *ngIf="marketValue == 'CA'">
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="Inclusive_Culture">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionOne" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="ev">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionTwo" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="online_sales">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionThree" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="ford_guests">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFour" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionFive" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="guest-representation">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSix" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionSeven" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
									<span></span>
								
									<a (click)="closeSideMenu()">
										<span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
											<p>{{ "DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartOne_SectionEight" | translate }}</p>
											<span class="arrowheadInSideMenu">
												<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
													<g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
														<rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
														<rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
													</g>
												</svg>
											</span>
										</span>
									</a>
								</div>
								
							</div>
						</section>
					</section>
	
	
					<section class="wrapSlideMenuBody">
						<div class="headerDivInSlideMenuBody">
							<h4><b>{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionSecond" | translate}}</b></h4>
							<p>{{ getCompletedSecondSectionsCount() }} {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.Of_Six_Completed" | translate}}</p>
						</div>
	
						<section class="mainNavSectionInSlideMenuBody">
							<div class="wrapDotsAndWords">
								<div class="navigationDotsInSlideMenu">
									<ng-container *ngFor="let secondStatus of secondSectionStatus; let i = index; let last = last">
										<a class="dots" [ngClass]="{ 'outerBorder': secondStatus }">
										  <div class="mini-dot" [ngClass]="{ 'activeDot': secondStatus }">
											<svg id="icon_-_checkmark_small" data-name="icon - checkmark small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                                                <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff"/>
                                                <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff"/>
                                            </svg>
										  </div>
										</a>
										<span *ngIf="!last"></span>
									</ng-container>
								</div>
								<div class="textCloseToDotsInSideMenu">
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="customer_to_guest"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionOne" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_vision"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionTwo" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="fge_principles"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionThree" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="we_have_got_you"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFour" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="treating_guests_like_family"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionFive" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
									<span></span>
									<a (click)="closeSideMenu()"><span class="wrapLinkInSideMenu" [routerLink]="['/onboarding/part-2']" fragment="what_is_in_it_for_me"><p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.PartTwo_SectionSix" | translate}}</p><span class="arrowheadInSideMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                                            <g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778 15.556) rotate(-135)">
                                            <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#6e6e6e"/>
                                            <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e"/>
                                            </g>
                                        </svg> </span></span></a>
								</div>
							</div>
						</section>
					</section>
					<button class="closeButtonInSideMenu" mat-raised-button (click)="closeSideMenu()">
						<div class="wrapTextAndSVGInSideMenu">
							<p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.CloseMenu" | translate}}</p>                     
							<svg id="icon_-_close_medium" data-name="icon - close medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
								<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
								<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
							  </svg> 
						</div>
					</button>					<!-- </mat-list-item> -->
			</mat-nav-list>
	</mat-drawer>
	<mat-drawer-content>

<div class="outerComponentColor min100vh" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
	<div class="wrapMapComponent">
		<nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
			<a [routerLink]="['/']">{{"DetailedSectionOne.SectionOneViewSectionPage.TopNavLinkOne" | translate}}</a>
			<span> > </span>
			<a [routerLink]="['/onboarding/part-1']"> {{"DetailedSectionOne.SectionOneViewSectionPage.TopNavLinkTwo" | translate}} </a>
			<span> > </span>
			<span id="sectionTitle"> {{ sectionTitle | translate}} </span>
		</nav>

<!-- <div *ngFor="let sectionItem of sections">	 -->
<!-- ------------------------- First Section ------------------------- -->

	<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[0].id && marketValue != 'US' && marketValue != 'CA'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="diversity">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionOne.SectionOneViewSectionPage.BackButton" | translate}}				
			</a>

			<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionOneViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="headingParagraph">{{"DetailedSectionOne.SectionOneViewSectionPage.SectionHeader" | translate}}</p>
			<p class="slightlyBiggerParagraph" [innerHTML]="'DetailedSectionOne.SectionOneViewSectionPage.SectionDescription' | translate | formatHtml"></p>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionOneViewSectionPage.SectionInstructions" | translate}}</i></p>

			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-1']" fragment="gender">
					<button mat-raised-button (click)="setSectionCompleted(sections[0].id, sectionTitle)">
						{{"DetailedSectionOne.SectionOneViewSectionPage.SectionCompleteButton" | translate}}
					</button></a>
				<p>{{"DetailedSectionOne.SectionOneViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageAlt' | translate}}">
			
			<svg class="svgModal1Section1 {{'DetailedSectionOne.SectionOneViewSectionPage.SpecialPosition1' | translate}}" tabindex="0" (click)="stopAnimation('circle1Modal1Section1', 'modal1', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section1 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section1 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>

			  <svg class="svgModal2Section1 {{'DetailedSectionOne.SectionOneViewSectionPage.SpecialPosition2' | translate}}" tabindex="0" (click)="stopAnimation('circle2Modal2Section1', 'modal2', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section1 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section1 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>

			  <svg class="svgModal3Section1 {{'DetailedSectionOne.SectionOneViewSectionPage.SpecialPosition3' | translate}}" tabindex="0" (click)="stopAnimation('circle3Modal3Section1', 'modal3', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle3Modal3Section1 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle3Modal3Section1 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>
			  
		</div>
	</section>
	<!-- <div class="btm-therms" *ngIf="sectionId === 'section-1'">
		<p><sup>1</sup> William H. Frey, “New Projections Point to a Majority Minority Nation in 2044,” Brookings Institution, December 12, 2014, available at <a target="_blank" href="http://www.brookings.edu/blogs/the-avenue/posts/2014/12/12-majority-minority-nation-2044-frey">http://www.brookings.edu/blogs/the-avenue/posts/2014/12/12-majority-minority-nation-2044-frey</a></p>		
	</div> --> 

	<!-- US Market -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[0].id && marketValue == 'US'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="Inclusive_Culture">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionOne.SectionOneViewSectionPage.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionOneViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>		
			<p class="headingParagraph">{{"DetailedSectionOne.SectionOneViewSectionPage.SectionHeader" | translate}}</p>	
			<p class="slightlyBiggerParagraph" [innerHTML]="'DetailedSectionOne.SectionOneViewSectionPage.SectionDescription_One' | translate | formatHtml"></p>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionOneViewSectionPage.SectionInstructions" | translate}}</i></p>
	
			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-1']" fragment="ev">
					<button mat-raised-button (click)="setSectionCompleted(sections[0].id, sectionTitle)">
						{{"DetailedSectionOne.SectionOneViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionOne.SectionOneViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageAlt' | translate}}">
	
			<svg class="svgModal3Section3_us" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal1Sec1_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>

			  <svg class="svgModal2Section3_us" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal2Sec1_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>

			  <svg class="svgModal1Section3" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal3Sec1_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>
		</div>
	</section>
	<div class="btm-therms" *ngIf="sectionId === sections[0].id && marketValue == 'US'">
		<p [innerHTML]="'DetailedSectionOne.SectionOneViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
	</div>

	<!-- CA Market -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[0].id && marketValue == 'CA'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="Inclusive_Culture">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionOne.SectionOneViewSectionPage.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionOneViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>		
			<p class="headingParagraph">{{"DetailedSectionOne.SectionOneViewSectionPage.SectionHeader" | translate}}</p>	
			<p class="slightlyBiggerParagraph" [innerHTML]="'DetailedSectionOne.SectionOneViewSectionPage.SectionDescription_One' | translate | formatHtml"></p>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionOneViewSectionPage.SectionInstructions" | translate}}</i></p>
	
			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-1']" fragment="ev">
					<button mat-raised-button (click)="setSectionCompleted(sections[0].id, sectionTitle)">
						{{"DetailedSectionOne.SectionOneViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionOne.SectionOneViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionOneViewSectionPage.ImageAlt' | translate}}">
	
			<svg class="svgModal3Section3_ca" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal1Sec1_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>

			  <svg class="svgModal2Section3_ca" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal2Sec1_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>

			  <svg class="svgModal1Section3" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal3Sec1_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>
		</div>
	</section>
	<!-- <div class="btm-therms" *ngIf="sectionId === sections[0].id && marketValue == 'CA'">
		<p [innerHTML]="'DetailedSectionOne.SectionOneViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
	</div> -->
<!-- ------------------------- End of the First Section ------------------------- -->

<!-- ------------------------- Second Section ------------------------- -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[1].id && marketValue != 'US' && marketValue != 'CA'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="gender">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionOne.SectionTwoViewSectionPage.BackButton" | translate}}
			</a>

			<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.SectionHeader' | translate | formatHtml"></p>
			<div class="slightlyBiggerParagraph">
				<ul>
					<li [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.SectionDescription_One' | translate | formatHtml"></li>
					<li [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.SectionDescription_Two' | translate | formatHtml"></li>
					<li class="{{'DetailedSectionOne.SectionTwoViewSectionPage.DoNotShowIfNotNeeded' | translate}}" [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.SectionDescription_Three' | translate | formatHtml"></li>
				</ul>
			</div>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionInstructions" | translate}}</i></p>

			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-1']" fragment="age">
					<button mat-raised-button (click)="setSectionCompleted(sections[1].id, sectionTitle)">
						{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
			<svg class="svgModal1Section2" tabindex="0" (click)="stopAnimation('circle1Modal1Section2', 'modalSection2Part1', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section2 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section2 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>
		</div>		
	</section>
	<div class="btm-therms" *ngIf="sectionId === sections[1].id && marketValue != 'US' && marketValue != 'CA'">
		<p [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
		<p [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.FooterNotes_Two' | translate | formatHtml"></p>
		<p [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.FooterNotes_Three' | translate | formatHtml"></p>
		<p [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.FooterNotes_Four' | translate | formatHtml"></p>
	</div>

	<!-- US Market -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[1].id && marketValue == 'US'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="ev">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionOne.SectionTwoViewSectionPage.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.SectionHeader' | translate | formatHtml"></p>
			<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionDescription_One" | translate}}</p>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionInstructions" | translate}}</i></p>
	
			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-1']" fragment="online_sales">
					<button mat-raised-button (click)="setSectionCompleted(sections[1].id, sectionTitle)">
						{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
	
			<svg class="svgModal1Section5" tabindex="0" (click)="stopAnimation('circle1Modal1Section5', 'modal5_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section5 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section5 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			  </svg>
		</div>
	</section>

	<!-- CA Market -->
	<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[1].id && marketValue == 'CA'">
		<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
			<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="ev">
				<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
					<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
					<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
					<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
					</g>
				</svg>				  
				{{"DetailedSectionOne.SectionTwoViewSectionPage.BackButton" | translate}}
			</a>
	
			<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionNumber" | translate}}</p>
			<h2>{{ sectionTitle | translate}}.</h2>
			<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionTwoViewSectionPage.SectionHeader' | translate | formatHtml"></p>
			<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionDescription_One" | translate}}</p>
			<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionInstructions" | translate}}</i></p>
	
			<div class="wrapCompleteButtonAndText">
				<a [routerLink]="['/onboarding/part-1']" fragment="online_sales">
					<button mat-raised-button (click)="setSectionCompleted(sections[1].id, sectionTitle)">
						{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionCompleteButton" | translate}}
					</button>
				</a>
				<p>{{"DetailedSectionOne.SectionTwoViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
			</div>
		</div>
		<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
			<img src="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionTwoViewSectionPage.ImageAlt' | translate}}">
	
			<svg class="svgModal1Section5" tabindex="0" (click)="stopAnimation('circle1Modal1Section5', 'modal5_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
				<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section5 clickable">
				  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
				</g>
				<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section5 clickable">
					<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
				  </g>  
				  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
					<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					  <circle cx="22" cy="22" r="22" stroke="none"/>
					  <circle cx="22" cy="22" r="21.5" fill="none"/>
					</g>
				  </g>
				<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
				</g>
			</svg>
		</div>
	</section>
	
<!-- ------------------------- End of the Second Section ------------------------- -->
<!-- </div> -->


<!-- ------------------------- Third Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[2].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="age">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionThreeViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph" [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.SectionDescription_One' | translate | formatHtml"></p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="generation">
				<button mat-raised-button (click)="setSectionCompleted(sections[2].id, sectionTitle)">
					{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section3" tabindex="0" (click)="stopAnimation('circle1Modal1Section3', 'modal4', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section3 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section3 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
	</div>
</section>
<div class="btm-therms" *ngIf="sectionId === sections[2].id && marketValue != 'US' && marketValue != 'CA'">
	<p [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
</div>


<!-- US Market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[2].id && marketValue == 'US'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="online_sales">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionThreeViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionDescription_One" | translate}}</p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="ford_guests">
				<button mat-raised-button (click)="setSectionCompleted(sections[2].id, sectionTitle)">
					{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">

		  <svg class="svgModal2Section6" tabindex="0" (click)="stopAnimation('circle2Modal2Section6', 'modal7_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section6 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section6 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>
		  
	</div>
	
</section>
<div class="btm-therms" *ngIf="sectionId === sections[2].id && marketValue == 'US'">
	<p [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
</div>

<!-- CA Market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[2].id && marketValue == 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="online_sales">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionThreeViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionDescription_One" | translate}}</p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="ford_guests">
				<button mat-raised-button (click)="setSectionCompleted(sections[2].id, sectionTitle)">
					{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionThreeViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionThreeViewSectionPage.ImageAlt' | translate}}">

		  <svg class="svgModal1Section6" tabindex="0" (click)="stopAnimation('circle1Modal1Section6', 'modal6_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section6 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section6 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>

		  <svg class="svgModal2Section6" tabindex="0" (click)="stopAnimation('circle2Modal2Section6', 'modal7_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section6 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section6 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>
		  
	</div>
	
</section>
<!-- <div class="btm-therms" *ngIf="sectionId === sections[2].id && marketValue == 'CA'">
	<p [innerHTML]="'DetailedSectionOne.SectionThreeViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
</div> -->
<!-- ------------------------- End of the Third Section ------------------------- -->

<!-- ------------------------- Fourth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[3].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="generation">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionFourViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionFourViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionFourViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionFourViewSectionPage.SectionDescription_One" | translate}}</p>
		<!-- <p class="slightlySmallerParagraph"></p> -->
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionFourViewSectionPage.SectionInstructions" | translate}}</i></p>		

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="evs">
				<button mat-raised-button (click)="setSectionCompleted(sections[3].id, sectionTitle)">
					{{"DetailedSectionOne.SectionFourViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>		
			<p>{{"DetailedSectionOne.SectionFourViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section4" tabindex="0" (click)="stopAnimation('circle1Modal1Section4', 'modalSection4Part1', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section4 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section4 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
	</div>
</section>

<!-- US Market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[3].id && marketValue == 'US'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="ford_guests">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionFourViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionFourViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionFourViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionFourViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionFourViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
				<button mat-raised-button (click)="setSectionCompleted(sections[3].id, sectionTitle)">
					{{"DetailedSectionOne.SectionFourViewSectionPage.SectionCompleteButton" | translate}}		
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionFourViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section7" tabindex="0" (click)="stopAnimation('circle1Modal1Section7', 'modal8_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal2Section7" tabindex="0" (click)="stopAnimation('circle2Modal2Section7', 'modal9_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal3Section7" tabindex="0" (click)="stopAnimation('circle3Modal3Section7', 'modal10_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle3Modal3Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle3Modal3Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal4Section7" tabindex="0" (click)="stopAnimation('circle4Modal4Section7', 'modal11_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle4Modal4Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle4Modal4Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>


		  <!-- <svg class="svgModal5Section7 {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.HideIfNeededGB' | translate}}" tabindex="0" (click)="stopAnimation('circle5Modal5Section7', 'modal12_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg> -->
	</div>
</section>

<!-- CA Market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[3].id && marketValue == 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="ford_guests">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionFourViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionFourViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionFourViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionFourViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionFourViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
				<button mat-raised-button (click)="setSectionCompleted(sections[3].id, sectionTitle)">
					{{"DetailedSectionOne.SectionFourViewSectionPage.SectionCompleteButton" | translate}}		
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionFourViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionFourViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section7" tabindex="0" (click)="stopAnimation('circle1Modal1Section7', 'modal8_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal2Section7" tabindex="0" (click)="stopAnimation('circle2Modal2Section7', 'modal9_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal3Section7" tabindex="0" (click)="stopAnimation('circle3Modal3Section7', 'modal10_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle3Modal3Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle3Modal3Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal4Section7" tabindex="0" (click)="stopAnimation('circle4Modal4Section7', 'modal11_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle4Modal4Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle4Modal4Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>


		  <!-- <svg class="svgModal5Section7 {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.HideIfNeededGB' | translate}}" tabindex="0" (click)="stopAnimation('circle5Modal5Section7', 'modal12_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg> -->
	</div>
</section>
<!-- ------------------------- End of the Fourth Section ------------------------- -->

<!-- ------------------------- Fifth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[4].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="evs">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionFiveViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionFiveViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="online_sales">
				<button mat-raised-button (click)="setSectionCompleted(sections[4].id, sectionTitle)">
					{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section5" tabindex="0" (click)="stopAnimation('circle1Modal1Section5', 'modal5', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section5 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section5 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
	</div>
</section>

<!-- US Market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[4].id && marketValue == 'US'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionFiveViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionFiveViewSectionPage.SectionHeader' | translate | formatHtml"></p>

		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="guest-representation">
				<button mat-raised-button (click)="setSectionCompleted(sections[4].id, sectionTitle)">
					{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section8" tabindex="0" (click)="stopAnimation('circle1Modal1Section8', 'modal13_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section8 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section8 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal2Section8" tabindex="0" (click)="stopAnimation('circle2Modal2Section8', 'modal14_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section8 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section8 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>

<!-- CA Market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[4].id && marketValue == 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionFiveViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionFiveViewSectionPage.SectionHeader' | translate | formatHtml"></p>

		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="guest-representation">
				<button mat-raised-button (click)="setSectionCompleted(sections[4].id, sectionTitle)">
					{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionFiveViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionFiveViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section8" tabindex="0" (click)="stopAnimation('circle1Modal1Section8', 'modal13_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section8 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section8 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal2Section8" tabindex="0" (click)="stopAnimation('circle2Modal2Section8', 'modal14_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section8 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section8 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>
<!-- ------------------------- End of the Fifth Section ------------------------- -->

<!-- ------------------------- Sixth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[5].id && marketValue != 'US' && marketValue !== 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="online_sales">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionSixViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionSixViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionSixViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionSixViewSectionPage.SectionDescription_One" | translate}}</p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="guest_personas">
				<button mat-raised-button (click)="setSectionCompleted(sections[5].id, sectionTitle)">
					{{"DetailedSectionOne.SectionSixViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionSixViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">

		  <svg class="svgModal2Section6" tabindex="0" (click)="stopAnimation('circle2Modal2Section6', 'modal7', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section6 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section6 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>
		  
	</div>
</section>
<div class="btm-therms" *ngIf="sectionId === sections[5].id && marketValue != 'US' && marketValue !== 'CA'">
	<p [innerHTML]="'DetailedSectionOne.SectionSixViewSectionPage.FooterNotes_One' | translate | formatHtml"></p>
</div>

<!-- US market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[5].id && marketValue == 'US'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="guest-representation">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionSixViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionSixViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionSixViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionSixViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionSixViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
				<button mat-raised-button (click)="setSectionCompleted(sections[5].id, sectionTitle)">
					{{"DetailedSectionOne.SectionSixViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionSixViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section9" tabindex="0" (click)="stopAnimation('circle1Modal1Section9', 'modal15_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section9 clickable">
				<circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section9 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>  
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					<circle cx="22" cy="22" r="22" stroke="none"/>
					<circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			</g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg>

		<svg class="svgModal2Section9 {{'DetailedSectionOne.SectionSixViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.HideIfNeededGB' | translate}}" tabindex="0" (click)="stopAnimation('circle2Modal2Section9', 'modal16_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section9 clickable">
				<circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section9 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					<circle cx="22" cy="22" r="22" stroke="none"/>
					<circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		</svg>		  
	</div>
</section>

<!-- CA market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[5].id && marketValue == 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="guest-representation">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionSixViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionSixViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionSixViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionSixViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionSixViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
				<button mat-raised-button (click)="setSectionCompleted(sections[5].id, sectionTitle)">
					{{"DetailedSectionOne.SectionSixViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionSixViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionSixViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section9" tabindex="0" (click)="stopAnimation('circle1Modal1Section9', 'modal15_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section9 clickable">
				<circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section9 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>  
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
					<circle cx="22" cy="22" r="22" stroke="none"/>
					<circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			</g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		</svg>
	  
	</div>
</section>
<!-- ------------------------- End of the Sixth Section ------------------------- -->

<!-- ------------------------- Seventh Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[6].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="guest_personas">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionSevenViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionSevenViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
				<button mat-raised-button (click)="setSectionCompleted(sections[6].id, sectionTitle)">
					{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionCompleteButton" | translate}}		
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>	
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section7" tabindex="0" (click)="stopAnimation('circle1Modal1Section7', 'modal8', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal2Section7" tabindex="0" (click)="stopAnimation('circle2Modal2Section7', 'modal9', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal3Section7" tabindex="0" (click)="stopAnimation('circle3Modal3Section7', 'modal10', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle3Modal3Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle3Modal3Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal4Section7" tabindex="0" (click)="stopAnimation('circle4Modal4Section7', 'modal11', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle4Modal4Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle4Modal4Section7 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>


		  <svg class="svgModal5Section7 {{'SharedItems.hideForCA' | translate}} {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.HideIfNeededGB' | translate}} " tabindex="0" (click)="stopAnimation('circle5Modal5Section7', 'modal12', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>

		  <!-- -------------------------------------------------- Modal 5 just for GB -------------------------------------------------- -->
		  <svg class="svgModal5Section7 {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.DontHideIfNeededGB' | translate}}" tabindex="0" (click)="stopAnimation('circle5Modal5Section7', 'modal12GB', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle5Modal5Section7 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>
		  <!-- -------------------------------------------------- End of Modal 5 just for GB -------------------------------------------------- -->

	</div>
</section>

<!-- US market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[6].id && marketValue == 'US'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionSevenViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">
			{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionNumber" | translate}}
		</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionSevenViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionInstructions" | translate}}</i></p>


		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
				<button mat-raised-button (click)="setSectionCompleted(sections[6].id, sectionTitle)">
					{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section10" tabindex="0" (click)="stopAnimation('circle1Modal1Section10', 'modal17_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section10 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section10 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>

<!-- CA market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[6].id && marketValue == 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionSevenViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">
			{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionNumber" | translate}}
		</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionSevenViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionInstructions" | translate}}</i></p>


		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
				<button mat-raised-button (click)="setSectionCompleted(sections[6].id, sectionTitle)">
					{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionOne.SectionSevenViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionSevenViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section10" tabindex="0" (click)="stopAnimation('circle1Modal1Section10', 'modal17_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section10 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section10 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>
<!-- ------------------------- End of the Seventh Section ------------------------- -->

<!-- ------------------------- Eighth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[7].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="happy_brain_anxious_brain">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionEightViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionEightViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionEightViewSectionPage.SectionHeader' | translate | formatHtml"></p>

		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionEightViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionEightViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a *ngIf="marketValue =='US'" [routerLink]="['/onboarding/part-2']">
				<button mat-raised-button (click)="setSectionCompleted(sections[7].id, sectionTitle)">
					{{"DetailedSectionOne.SectionEightViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>

			<a *ngIf="marketValue !='US'" [routerLink]="['/onboarding/part-1']" fragment="guest_representation">
				<button mat-raised-button (click)="setSectionCompleted(sections[7].id, sectionTitle)">
					{{"DetailedSectionOne.SectionEightViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionEightViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section8" tabindex="0" (click)="stopAnimation('circle1Modal1Section8', 'modal13', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section8 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section8 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  <svg class="svgModal2Section8" tabindex="0" (click)="stopAnimation('circle2Modal2Section8', 'modal14', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section8 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section8 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>

<!-- US market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[7].id && marketValue == 'US'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionEightViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionEightViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionEightViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionEightViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-2']">
				<button mat-raised-button (click)="setSectionCompleted(sections[7].id, sectionTitle)">
					{{"DetailedSectionOne.SectionEightViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionOne.SectionEightViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section11" tabindex="0" (click)="stopAnimation('circle1Modal1Section11', 'modal18_us', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section11 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section11 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>

<!-- CA market -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[7].id && marketValue == 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionEightViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionEightViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionEightViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionEightViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-2']">
				<button mat-raised-button (click)="setSectionCompleted(sections[7].id, sectionTitle)">
					{{"DetailedSectionOne.SectionEightViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionOne.SectionEightViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionEightViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section11" tabindex="0" (click)="stopAnimation('circle1Modal1Section11', 'modal18_ca', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section11 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section11 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>
<!-- ------------------------- End of the Eighth Section ------------------------- -->
<span *ngIf="marketValue != 'US' && marketValue != 'CA'">
<!-- ------------------------- Ninth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[8].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="guest_representation">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionNineViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionNineViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionNineViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionNineViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionNineViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
				<button mat-raised-button (click)="setSectionCompleted(sections[8].id, sectionTitle)">
					{{"DetailedSectionOne.SectionNineViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>{{"DetailedSectionOne.SectionNineViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionNineViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionNineViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section9" tabindex="0" (click)="stopAnimation('circle1Modal1Section9', 'modal15', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section9 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section9 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>

		  <svg class="svgModal2Section9 {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.HideIfNeededGB' | translate}}" tabindex="0" (click)="stopAnimation('circle2Modal2Section9', 'modal16', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section9 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section9 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>

		  <!-- ------------------------------------------------ Modal 2 just for GB ------------------------------------------------ -->
		  <svg class="svgModal2Section9 {{'DetailedSectionOne.SectionNineViewSectionPage.HideIfNeeded' | translate}} {{'DetailedSectionOne.DontHideIfNeededGB' | translate}}" tabindex="0" (click)="stopAnimation('circle2Modal2Section9', 'modal16GB', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle2Modal2Section9 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle2Modal2Section9 clickable">
			  <circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
			  <g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				<circle cx="22" cy="22" r="22" stroke="none"/>
				<circle cx="22" cy="22" r="21.5" fill="none"/>
			  </g>
			</g>
			<path id="Union_45" data-name="Union 45" d="M-5944-517a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h6a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm1-4a2,2,0,0,1-2-2v-1.934a9,9,0,0,1-5-8.066,9,9,0,0,1,9-9,9,9,0,0,1,9,9,9,9,0,0,1-5,8.066V-527a2,2,0,0,1-2,2Z" transform="translate(5981 573)" fill="#fff"/>
		  </svg>
		  <!-- ------------------------------------------------ END of Modal 2 just for GB ------------------------------------------------ -->

		  
	</div>
</section>
<!-- ------------------------- End of the Ninth Section ------------------------- -->

<!-- ------------------------- Tenth Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[9].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="relationship_building_with_guests">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionTenViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">
			{{"DetailedSectionOne.SectionTenViewSectionPage.SectionNumber" | translate}}
		</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="headingParagraph" [innerHTML]="'DetailedSectionOne.SectionTenViewSectionPage.SectionHeader' | translate | formatHtml"></p>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionTenViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionTenViewSectionPage.SectionInstructions" | translate}}</i></p>


		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
				<button mat-raised-button (click)="setSectionCompleted(sections[9].id, sectionTitle)">
					{{"DetailedSectionOne.SectionTenViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionOne.SectionTenViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionTenViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionTenViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section10" tabindex="0" (click)="stopAnimation('circle1Modal1Section10', 'modal17', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section10 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section10 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>
<!-- ------------------------- End of the Tenth Section ------------------------- -->

<!-- ------------------------- Eleventh Section ------------------------- -->
<section class="sectionForTheDetailedPage" *ngIf="sectionId === sections[10].id && marketValue != 'US' && marketValue != 'CA'">
	<div class="wrapTextInsideSectionForTheDetailedPage" data-aos="fade-right" data-aos-delay="500">
		<a class="goBackLink" [routerLink]="['/onboarding/part-1']" fragment="last_best_experience">
			<svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
				<g id="icon_-_arrow_medium" data-name="icon - arrow medium" transform="translate(7.778) rotate(45)">
				<rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="11" rx="1" fill="#00095b"/>
				<rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#00095b"/>
				</g>
			</svg>				  
			{{"DetailedSectionOne.SectionElevenViewSectionPage.BackButton" | translate}}
		</a>

		<p class="sectionOnDetailedSection">{{"DetailedSectionOne.SectionElevenViewSectionPage.SectionNumber" | translate}}</p>
		<h2>{{ sectionTitle | translate}}.</h2>
		<p class="slightlyBiggerParagraph">{{"DetailedSectionOne.SectionElevenViewSectionPage.SectionDescription_One" | translate}}</p>
		<p class="slightlySmallerParagraph"><i>{{"DetailedSectionOne.SectionElevenViewSectionPage.SectionInstructions" | translate}}</i></p>

		<div class="wrapCompleteButtonAndText">
			<a [routerLink]="['/onboarding/part-2']">
				<button mat-raised-button (click)="setSectionCompleted(sections[10].id, sectionTitle)">
					{{"DetailedSectionOne.SectionElevenViewSectionPage.SectionCompleteButton" | translate}}
				</button>
			</a>
			<p>
				{{"DetailedSectionOne.SectionElevenViewSectionPage.SectionInstructionUnderCompleteButton" | translate}}
			</p>
		</div>
	</div>
	<div class="wrapImageInsideSectionForTheDetailedPage" data-aos="fade-left" data-aos-delay="800">
		<img src="{{'DetailedSectionOne.SectionElevenViewSectionPage.ImageSource' | translate}}" alt="{{'DetailedSectionOne.SectionElevenViewSectionPage.ImageAlt' | translate}}">

		<svg class="svgModal1Section11" tabindex="0" (click)="stopAnimation('circle1Modal1Section11', 'modal18', sectionTitle)" id="button_-_interactive" data-name="button - interactive" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
			<g id="button_container_-_round" data-name="button container - round" opacity="0.05" class="button-container-round circle1Modal1Section11 clickable">
			  <circle id="Ellipse_1" data-name="Ellipse 1" cx="40" cy="40" r="40" fill="#066FEF"/>
			</g>
			<g id="button_container_-_round-2" data-name="button container - round" transform="translate(13 13)" opacity="0.2" class="button-container-round-2 circle1Modal1Section11 clickable">
				<circle id="Ellipse_1-2" data-name="Ellipse 1" cx="27" cy="27" r="27" fill="#066FEF"/>
			  </g>  
			  <g id="button_container_-_round-3" data-name="button container - round" transform="translate(18 18)">
				<g id="Ellipse_1-3" data-name="Ellipse 1" fill="#066FEF" stroke="#066FEF" stroke-width="1">
				  <circle cx="22" cy="22" r="22" stroke="none"/>
				  <circle cx="22" cy="22" r="21.5" fill="none"/>
				</g>
			  </g>
			<g id="icon_-_plus_medium" data-name="icon - plus medium" transform="translate(30 30)">
			  <rect id="Rectangle_10" data-name="Rectangle 10" width="2" height="20" transform="translate(9)" fill="#fff"/>
			  <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="20" transform="translate(0 11) rotate(-90)" fill="#fff"/>
			</g>
		  </svg>
		  
	</div>
</section>
<!-- ------------------------- End of the Eleventh Section ------------------------- -->

</span>


	</div>
		<!-- Footer -->
		<footer class="footerForMapComponent">
			<div class="wrapfootercomponents">
			<section>
					<p>
						<span class="{{'DetailedSectionOne.AnimatedMapFooter.longWordsInTheDetailSectionFooterHolder' | translate}}" >
							<span class="blueBoldText">
								{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionOne_Pre" | translate}}	
							</span>
							<span class="solveSpacingViewSection {{'DetailedSectionOne.AnimatedMapFooter.longWordsInTheDetailSectionFooter' | translate}}">
								{{"DetailedSectionOne.AnimatedMapFooter.LeftSectionOne" | translate}}
							</span>
						</span>

						<!-- <br class="showOnMobileOnly" />  -->
						<span class="dontShowOnMobile">|</span> <span class="lightBlueText longWordsShallNotPass {{'DetailedSectionOne.SectionNineViewSectionPage.longWordsShallNotPassGB' | translate}} {{'DetailedSectionOne.SectionNineViewSectionPage.longWordsShallNotPassFR' | translate}}">{{ sectionTitle | translate}}</span>
					</p>
			</section>

			<div class="containerInMap" >
				<ng-container *ngFor="let status of sectionStatus; let i = index; let last = last">
					<a (click)="toggleMenu()" class="dots" [ngClass]="{ 'outerBorder': status }">
					  <div class="mini-dot" [ngClass]="{ 'activeDot': status }"></div>
					</a>
					<span *ngIf="!last"></span>
				</ng-container>
			</div>
	
			<section>
				<span class="viewSections">{{"DetailedSectionOne.AnimatedMapFooter.RightSection" | translate}}</span>
				<button (click)="hideConfirmation(); closeToastOnClick(); drawer.toggle()" mat-icon-button class="menu-icon" aria-label="Example icon-button with menu icon">
					<!-- <mat-icon>menu</mat-icon> -->
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                        <g id="icon_-_hamburger_menu_-_default" data-name="icon - hamburger menu - default" transform="translate(6)">
                          <rect id="Rectangle_11" data-name="Rectangle 11" width="2" height="18" rx="1" transform="translate(0 2) rotate(-90)" fill="#066FEF"/>
                          <rect id="Rectangle_169" data-name="Rectangle 169" width="2" height="18" rx="1" transform="translate(0 9) rotate(-90)" fill="#066FEF"/>
                          <rect id="Rectangle_170" data-name="Rectangle 170" width="2" height="18" rx="1" transform="translate(0 16) rotate(-90)" fill="#066FEF"/>
                        </g>
                        <g id="icon_-_hamburger_menu_-_default-2" data-name="icon - hamburger menu - default">
                          <rect id="Rectangle_11-2" data-name="Rectangle 11" width="2" height="3" rx="1" transform="translate(0 2) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_169-2" data-name="Rectangle 169" width="2" height="3" rx="1" transform="translate(0 9) rotate(-90)" fill="#0f0f0f"/>
                          <rect id="Rectangle_170-2" data-name="Rectangle 170" width="2" height="3" rx="1" transform="translate(0 16) rotate(-90)" fill="#0f0f0f"/>
                        </g>
                      </svg>
				</button>
			</section>
		</div>
	</footer>
</div>

</mat-drawer-content>
</mat-drawer-container>